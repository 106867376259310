import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { Container, Box, Divider, Chip, Grid, Alert, Typography } from "@mui/material";
import Stepper from 'src/components/Stepper';
import ReactDOM from "react-dom";
import Countdown from "react-countdown";
import 'src/assets/css/custom.css';
import TimerIcon from '@mui/icons-material/Timer';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { purple } from '@mui/material/colors';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18n from 'src/i18n/i18n';
import * as Yup from "yup";
import http, { ROUTES } from 'src/utils/httpHelper';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { useSnackbar } from 'notistack';

// Completion
const Completionist = () => AlertDialog();

// Renderer callback with condition
const renderer = ({ minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span>
        {minutes}:{seconds}
      </span>
    );
  }
};

// Completion Dialog
function AlertDialog() {
  return (
    <Dialog open={true} PaperProps={{ elevation: 1 }} disableEnforceFocus>
      <DialogTitle>
        <Typography sx={{ mt: 2 }} variant="h3" style={{ fontWeight: "bold", display: 'flex', alignItems: 'center' }}>
          <TimerIcon />
          ¡Tiempo agotado!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4" style={{ fontWeight: 500, display: 'flex', alignItems: 'center' }}>
          Se agotó el tiempo disponible para agendar tu cita. Porfavor, inténtalo de nuevo.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button href="/info" sx={{ color: purple[600] }} autoFocus>
          Volver al inicio
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const generateVerificationDigit = (curp17: string) => {
  let dictionary = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ";
  let sum = 0;
  for (var i = 0; i < 17; i++)
    sum = sum + dictionary.indexOf(curp17.charAt(i)) * (18 - i);
  let digit = 10 - sum % 10;
  if (digit == 10) return 0;
  return digit;
}

const validationSchema = Yup.object().shape({
  formality_id: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  licence_type_id: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  module_id: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  appointment_date: Yup.date()
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .required(i18n.t("VALIDATION.REQUIRED")),
  appointment_time: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),

  licences_year_id: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  curp: Yup.string()
    .matches(/[A-Z]{1}[AEIOUX]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/, "CURP no válida")
    .test("invalid_verification_number", "CURP no válida", (curp, ctx) => {
      let verificationDigit = parseInt(curp.charAt(curp.length - 1));
      let curp17 = curp.slice(0, -1);
      return verificationDigit == generateVerificationDigit(curp17);
    })
    .required(i18n.t("VALIDATION.REQUIRED")),
  name: Yup.string()
    .matches(/^[a-zA-ZÀ-ÿñÑ]+(\s*[a-zA-ZÀ-ÿñÑ]*)*[a-zA-ZÀ-ÿñÑ]+$/, "Nombre no valido, no introduzca simbolos ni espacios al final del nombre")
    .required(i18n.t("VALIDATION.REQUIRED")),
  last_name: Yup.string()
    .matches(/^[a-zA-ZÀ-ÿñÑ]+(\s*[a-zA-ZÀ-ÿñÑ]*)*[a-zA-ZÀ-ÿñÑ]+$/, "Apellido no válido, no introduzca simbolos ni espacios al final del nombre")
    .required("Si solo cuenta con un apellido, introdúzcalo en el campo Primer Apellido"),
  second_last_name: Yup.lazy(value=>!value ? Yup.string() : Yup.string().matches(/^[a-zA-ZÀ-ÿñÑ]+(\s*[a-zA-ZÀ-ÿñÑ]*)*[a-zA-ZÀ-ÿñÑ]+$/, "Apellido no válido, no introduzca simbolos ni espacios al final del nombre")),
  sex: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  rfc: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED"))
    .matches(/^([A-Z\s]{4})\d{6}([A-Z\w]{3})?$/, "Colocar RFC válido")
    .when('second_last_name', {
      is: (secondLastName) => secondLastName != null && secondLastName != undefined && secondLastName != '',
      then: Yup.string().test('curp_rfc_not_matching', 'El RFC no coincide con la curp', (value, ctx) => {
        return ctx.parent.curp.substr(0, 10) === value.substring(0, 10);
      }),
      otherwise: Yup.string(), 
    }),
  birthdate: Yup.date()
    .typeError('Fecha no válida')
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .test('curp_date_birthdate_not_matching', 'La fecha no coincide con la de la curp', (value, ctx) => {
      return ctx.parent.curp.substr(4, 6) == moment(value).format('YYMMDD');
    })
    .required(i18n.t("VALIDATION.REQUIRED")),
  marital_status: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  nationality_id: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  phone: Yup.string()
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .matches(/[0-9]{10}/, "Colocar un número telefónico válido")
    .required(i18n.t("VALIDATION.REQUIRED")),
  email: Yup.string()
    .email(i18n.t('VALIDATION.EMAIL'))
    .required(i18n.t("VALIDATION.REQUIRED")),
  occupation: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),

  prefix: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  street: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  ext_number: Yup.number()
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .required(i18n.t("VALIDATION.REQUIRED")),
  int_number: Yup.string()
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr),
  postal_code: Yup.string()
    .nullable()
    .matches(/[0-9]{5}/, "Código postal no válido")
    .transform((curr, orig) => orig === '' ? null : curr)
    .required(i18n.t("VALIDATION.REQUIRED")),
  postal_code_id: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  state_id: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  municipality_id: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),
  neighborhood_id: Yup.string()
    .required(i18n.t("VALIDATION.REQUIRED")),

  contact_name: Yup.string()
  .matches(/^[a-zA-ZÀ-ÿñÑ]+(\s*[a-zA-ZÀ-ÿñÑ]*)*[a-zA-ZÀ-ÿñÑ]+$/, "Nombre no valido, no introduzca simbolos ni espacios al final del nombre")
    .required(i18n.t("VALIDATION.REQUIRED")),
  contact_last_name: Yup.string()
  .matches(/^[a-zA-ZÀ-ÿñÑ]+(\s*[a-zA-ZÀ-ÿñÑ]*)*[a-zA-ZÀ-ÿñÑ]+$/, "Apellido no valido, no introduzca simbolos ni espacios al final del nombre")
    .required(i18n.t("VALIDATION.REQUIRED")),
  contact_second_last_name: Yup.lazy(value=>!value ? Yup.string() : Yup.string().matches(/^[a-zA-ZÀ-ÿñÑ]+(\s*[a-zA-ZÀ-ÿñÑ]*)*[a-zA-ZÀ-ÿñÑ]+$/, "Apellido no valido, no introduzca simbolos ni espacios al final del nombre")),
  contact_phone: Yup.string()
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .matches(/[0-9]{10}/, "Colocar un número telefónico válido")
    .required(i18n.t("VALIDATION.REQUIRED")),

  tutor_name: Yup.string()
  .when("licence_type_id", {
    is: (licenceTypeId) => licenceTypeId == '6' || licenceTypeId == '7',
    then: Yup.string().when("formality_id", {
      is: (formalityId) => formalityId == '1',
      then: Yup.string().required(i18n.t("VALIDATION.REQUIRED"))
        .matches(/^[a-zA-ZÀ-ÿñÑ]+(\s*[a-zA-ZÀ-ÿñÑ]*)*[a-zA-ZÀ-ÿñÑ]+$/, "Nombre no valido, no introduzca simbolos ni espacios al final del nombre"),
    }),
  }),
  tutor_last_name: Yup.string()
  .when("licence_type_id", {
    is: (licenceTypeId) => licenceTypeId == '6' || licenceTypeId == '7',
    then: Yup.string().when("formality_id", {
      is: (formalityId) => formalityId == '1',
      then: Yup.string().required(i18n.t("VALIDATION.REQUIRED"))
        .matches(/^[a-zA-ZÀ-ÿñÑ]+(\s*[a-zA-ZÀ-ÿñÑ]*)*[a-zA-ZÀ-ÿñÑ]+$/, "Apellido no valido, no introduzca simbolos ni espacios al final del nombre"),
    }),
  }),
  tutor_second_last_name: Yup.string()
  .when("licence_type_id", {
    is: (licenceTypeId) => licenceTypeId == '6' || licenceTypeId == '7',
    then: Yup.string().when("formality_id", {
      is: (formalityId) => formalityId == '1',
      then: Yup.lazy(value=>!value ? Yup.string() : Yup.string().matches(/^[a-zA-ZÀ-ÿñÑ]+(\s*[a-zA-ZÀ-ÿñÑ]*)*[a-zA-ZÀ-ÿñÑ]+$/, "Apellido no valido, no introduzca simbolos ni espacios al final del nombre")),
    }),
  }),
  tutor_phone: Yup.string()
  .when("licence_type_id", {
    is: (licenceTypeId) => licenceTypeId == '6' || licenceTypeId == '7',
    then: Yup.string().when("formality_id", {
      is: (formalityId) => formalityId == '1',
      then: Yup.string()
        .nullable()
        .transform((curr, orig) => orig === '' ? null : curr)
        .matches(/[0-9]{10}/, "Colocar un número telefónico válido")
        .required(i18n.t("VALIDATION.REQUIRED"))
      }),
  }),
  tutor_curp: Yup.string()
  .when("licence_type_id", {
    is: (licenceTypeId) => licenceTypeId == '6' || licenceTypeId == '7',
    then: Yup.string().when("formality_id", {
      is: (formalityId) => formalityId == '1',
      then: Yup.string()
        .matches(/[A-Z]{1}[AEIOUX]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/, "CURP no válida")
        .test("invalid_verification_number", "CURP no válida", (curp, ctx) => {
          let verificationDigit = parseInt(curp.charAt(curp.length - 1));
          let curp17 = curp.slice(0, -1);
          return verificationDigit == generateVerificationDigit(curp17);
        })
        .required(i18n.t("VALIDATION.REQUIRED"))
    }),
  }),
  tutor_email: Yup.string()
  .when("licence_type_id", {
    is: (licenceTypeId) => licenceTypeId == '6' || licenceTypeId == '7',
    then: Yup.string().when("formality_id", {
      is: (formalityId) => formalityId == '1',
      then: Yup.string()
        .email(i18n.t('VALIDATION.EMAIL'))
        .required(i18n.t("VALIDATION.REQUIRED"))
    }),
  }),
  tutor_rfc: Yup.string()
  .when("licence_type_id", {
    is: (licenceTypeId) => licenceTypeId == '6' || licenceTypeId == '7',
    then: Yup.string().when("formality_id", {
      is: (formalityId) => formalityId == '1',
      then: Yup.string()
        .required(i18n.t("VALIDATION.REQUIRED"))
        .matches(/^([A-Z\s]{4})\d{6}([A-Z\w]{3})?$/, "Colocar RFC válido")
        .test('tutor_curp_rfc_not_matching', 'El RFC no coincide con la curp', (value, ctx) => {
          return ctx.parent.tutor_curp.substr(0, 10) == value.substring(0, 10);
        }),
    }),
  }),

  tutor_prefix: Yup.string()
  .when("licence_type_id", {
    is: (licenceTypeId) => licenceTypeId == '6' || licenceTypeId == '7',
    then: Yup.string().when("formality_id", {
      is: (formalityId) => formalityId == '1',
      then: Yup.string()
          .required(i18n.t("VALIDATION.REQUIRED"))
    }),
  }),

  tutor_occupation: Yup.string()
  .when("licence_type_id", {
    is: (licenceTypeId) => licenceTypeId == '6' || licenceTypeId == '7',
    then: Yup.string().when("formality_id", {
      is: (formalityId) => formalityId == '1',
      then: Yup.string()
        .required(i18n.t("VALIDATION.REQUIRED"))
    }),
  }),
  
  tutor_street: Yup.string()
  .when("licence_type_id", {
    is: (licenceTypeId) => licenceTypeId == '6' || licenceTypeId == '7',
    then: Yup.string().when("formality_id", {
      is: (formalityId) => formalityId == '1',
      then: Yup.string()
        .required(i18n.t("VALIDATION.REQUIRED"))
    }),
  }),

  tutor_int_number: Yup.string()
  .when("licence_type_id", {
  is: (licenceTypeId) => licenceTypeId == '6' || licenceTypeId == '7',
  then: Yup.string().when("formality_id", {
    is: (formalityId) => formalityId == '1',
    then: Yup.string()
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
    }),
  }),

  tutor_ext_number: Yup.string()
  .when("licence_type_id", {
    is: (licenceTypeId) => licenceTypeId == '6' || licenceTypeId == '7',
    then: Yup.string().when("formality_id", {
      is: (formalityId) => formalityId == '1',
      then: Yup.string()
        .nullable()
        .transform((curr, orig) => orig === '' ? null : curr)
        .required(i18n.t("VALIDATION.REQUIRED"))
    }),
  }),
  tutor_postal_code: Yup.string()
  .when("licence_type_id", {
    is: (licenceTypeId) => licenceTypeId == '6' || licenceTypeId == '7',
    then: Yup.string().when("formality_id", {
      is: (formalityId) => formalityId == '1',
      then: Yup.string()
          .nullable()
          .min(5, "Código postal no válido")
          .max(5, "Código postal no válido")
          .transform((curr, orig) => orig === '' ? null : curr)
          .required(i18n.t("VALIDATION.REQUIRED"))
    }),
  }),

  tutor_postal_code_id: Yup.string()
  .when("licence_type_id", {
    is: (licenceTypeId) => licenceTypeId == '6' || licenceTypeId == '7',
    then: Yup.string().when("formality_id", {
      is: (formalityId) => formalityId == '1',
      then: Yup.string()
          .required(i18n.t("VALIDATION.REQUIRED"))
    }),
  }),

  tutor_state_id: Yup.string()
  .when("licence_type_id", {
    is: (licenceTypeId) => licenceTypeId == '6' || licenceTypeId == '7',
    then: Yup.string().when("formality_id", {
      is: (formalityId) => formalityId == '1',
      then: Yup.string()
          .required(i18n.t("VALIDATION.REQUIRED"))
    }),
  }),

  tutor_municipality_id: Yup.string()
  .when("licence_type_id", {
    is: (licenceTypeId) => licenceTypeId == '6' || licenceTypeId == '7',
    then: Yup.string().when("formality_id", {
      is: (formalityId) => formalityId == '1',
      then: Yup.string()
          .required(i18n.t("VALIDATION.REQUIRED"))
    }),
  }),

  tutor_neighborhood_id: Yup.string()
  .when("licence_type_id", {
  is: (licenceTypeId) => licenceTypeId == '6' || licenceTypeId == '7',
  then: Yup.string().when("formality_id", {
    is: (formalityId) => formalityId == '1',
    then: Yup.string()
          .required(i18n.t("VALIDATION.REQUIRED"))
    }),
  }),

  tutor_sex: Yup.string()
  .when("licence_type_id", {
  is: (licenceTypeId) => licenceTypeId == '6' || licenceTypeId == '7',
  then: Yup.string().when("formality_id", {
    is: (formalityId) => formalityId == '1',
    then: Yup.string()
          .required(i18n.t("VALIDATION.REQUIRED"))
    }),
  }),

  tutor_marital_status: Yup.string()
  .when("licence_type_id", {
  is: (licenceTypeId) => licenceTypeId == '6' || licenceTypeId == '7',
  then: Yup.string().when("formality_id", {
    is: (formalityId) => formalityId == '1',
    then: Yup.string()
          .required(i18n.t("VALIDATION.REQUIRED"))
    }),
  }),

  tutor_nationality_id: Yup.string()
  .when("licence_type_id", {
  is: (licenceTypeId) => licenceTypeId == '6' || licenceTypeId == '7',
  then: Yup.string().when("formality_id", {
    is: (formalityId) => formalityId == '1',
    then: Yup.string()
          .required(i18n.t("VALIDATION.REQUIRED"))
    }),
  }),

  fm3: Yup.string()
    .when("nationality_id", (nationality_id) => {
      if (nationality_id != 1) {
        return Yup.string()
          .required(i18n.t("VALIDATION.REQUIRED"))
      }
    }),
});


const defaultFormData = {
  id: '',
  formality_name: '',
  formality_id: '',
  licence_type_id: '',
  module_id: '',
  appointment_date: '',
  appointment_time: '',

  licences_year_id: '',

  curp: '',
  name: '',
  last_name: '',
  second_last_name: '',
  sex: '',
  rfc: '',
  birthdate: '',
  marital_status: '',
  nationality_id: '1',
  phone: '',
  email: '',
  occupation: '',

  prefix: '',
  street: '',
  int_number: '',
  ext_number: '',
  postal_code: '',
  postal_code_id: '',
  state_id: '',
  municipality_id: '',
  neighborhood_id: '',

  contact_name: '',
  contact_last_name: '',
  contact_second_last_name: '',
  contact_phone: '',

  tutor_name: '',
  tutor_last_name: '',
  tutor_second_last_name: '',
  tutor_phone: '',
  /* ----------------- */
  tutor_email: '',
  tutor_curp: '',
  tutor_sex: '',
  tutor_rfc: '',
  tutor_birthdate: '',
  tutor_marital_status: '',
  tutor_nationality_id: '1',
  tutor_occupation: '',
  tutor_prefix: '',
  tutor_street: '',
  tutor_int_number: '',
  tutor_ext_number: '',
  tutor_postal_code: '',
  tutor_postal_code_id: '',
  tutor_state_id: '',
  tutor_municipality_id: '',
  tutor_neighborhood_id: '',

  fm3: ''
};

function Process() {
  //aqui
  const countdown = useRef(Date.now() + 900000);
  const countdownComponent = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const { watch, formState: { errors }, control, setValue, getValues, trigger } = useForm({
    mode: 'all',
    defaultValues: defaultFormData,
    resolver: yupResolver(validationSchema),
  });
  const watchFormalityId = watch("formality_id");
  const watchLicenceTypeId = watch("licence_type_id");
  const watchModuleId = watch("module_id");
  const [selectedCalendarDate, setSelectedCalendarDate] = useState(null);
  const [selectedBirthdate, setSelectedBirthdate] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(moment().utc().month() + 1);
  const [selectedYear, setSelectedYear] = useState(moment().utc().year());
  const [amount, setAmount] = useState('');
  const [age, setAge] = useState('');
  const [tutorAge, setTutorAge] = useState('');
  const [showTimer, setShowTimer] = useState(false);

  const [selectsData, setSelectsData] = useState({
    modules: [],
    licenceTypes: [],
    selectedLicenceYears: [],
    systemAddresses: [],
    states: [],
    municipalities: [],
    neighborhoods: [],
    tutorStates: [],
    tutorMunicipalities: [],
    tutorNeighborhoods: [],
    calendarDates: [],
    availableHours: [],
    unavailableDays: [],
    nonworkingDays: [],
    nationalities: []
  });

  useEffect(() => {
    setValue('formality_id', searchParams.get('formality_id'));
    http.post(ROUTES.SELECTS_DATA,
      {
        formality_id: searchParams.get('formality_id')
      })
      .then((data: any) => {
        setSelectsData({
          ...selectsData,
          modules: data.modules,
          licenceTypes: data.licenceTypes,
          systemAddresses: data.systemAddresses,
          nationalities: data.nationalities
        })
      })
      .catch(error => {
        enqueueSnackbar(
          error.message != '' ? error.message :
            'Hubo un error al obtener los datos necesarios para el formulario.\n' + error.exception,
          { variant: 'error' });
      });
  }, []);

  useEffect(() => {
    if (watchLicenceTypeId && watchModuleId) {
      let selectedLicenceType = selectsData.licenceTypes
        .find(licenceType => (licenceType.id + "") == watchLicenceTypeId);

      http.post(ROUTES.CALENDAR_AVAILABLE_DATES, {
        year: selectedYear,
        month: selectedMonth,
        module_id: watchModuleId,
        licence_type_id: watchLicenceTypeId,
        formality_id: searchParams.get('formality_id')
      })
        .then((calendarAvailableHours: any) => {
          let unavailableDays = [];
          let nonworkingDays = [];
          Object.keys(calendarAvailableHours).forEach(day => {
            if(!calendarAvailableHours[day]){
              nonworkingDays.push(day);
            }else if (!calendarAvailableHours[day].length) {
              unavailableDays.push(day);
            }
          });
          setValue('appointment_date', '');
          setValue('appointment_time', '');
          setSelectedCalendarDate(null);
          setSelectsData({ ...selectsData, calendarDates: calendarAvailableHours, nonworkingDays, unavailableDays, availableHours: [], selectedLicenceYears: selectedLicenceType.years })
        })
        .catch(error => {
          enqueueSnackbar(
            error.message != '' ? error.message :
              'Hubo un error al obtener los datos necesarios para el formulario.\n' + error.exception,
            { variant: 'error' });
        });
    }
  }, [watchLicenceTypeId, watchModuleId, selectedMonth, selectedYear]);

  return (
    <>
      <Helmet>
        <title>Proceso</title>
      </Helmet>

      <Container maxWidth="xl" id="logo-bar">
        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <Box style={{ marginTop: 20 }}>
              <img src={require('../../assets/img/logo.png')} width="20%" />
            </Box>
          </Grid>
          <Grid item xs={6} md={6} sx={{ alignSelf: "center" }} style={showTimer ? {} : { display: 'none' }}>
            <Box style={{ marginTop: 20, textAlign: "right" }}>
              <Typography variant="h4" sx={{ pb: 1, pt: 0 }}>
                Tiempo restante para agendar cita:
              </Typography>
              <Chip color="error" icon={<TimerIcon />} label={<Countdown ref={countdownComponent} autoStart={false} date={countdown.current} renderer={renderer} />} sx={{ fontWeight: "bold" }} />
            </Box>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 10, marginBottom: 5 }}></Divider>
      </Container>

      <Container maxWidth="xl" sx={{ mb: 10 }}>

        <form noValidate>
          <Stepper control={control} generalDataController={{
            selectsData,
            setSelectsData,
            selectedMonth,
            setSelectedMonth,
            selectedYear,
            setSelectedYear,
            watchLicenceTypeId,
            watchModuleId,
            selectedCalendarDate,
            setSelectedCalendarDate,
            amount,
            setAmount,
            age,
            setAge,
            showTimer,
            setShowTimer,
            setValue,
            getValues,
            errors,
            trigger,
            enqueueSnackbar,
            watchFormalityId,
            selectedBirthdate,
            setSelectedBirthdate,
            countdownComponent,
            tutorAge,
            setTutorAge
          }} />
        </form>
      </Container>
    </>
  );
}

const styles = ({
  mainContainer: {
    flex: 1,
    backgroundColor: 'transparent',
    resizeMode: 'cover',
    justifyContent: 'center',
    alignItems: 'center',
  }
});

export default Process;