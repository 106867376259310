import React from "react";
import DescriptionIcon from '@mui/icons-material/Description';
import { Grid, Typography, Alert, ListItemText, List, ListItem, ListItemIcon } from "@mui/material";

function Block1() {
    return (
        <>
            <style>
                {`
                    .MuiAlert-icon {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                `}
            </style>
            <Grid item sm={8} sx={{ textAlign: "center", backgroundColor: "#004EAA", py: 1, color: "#fff" }}>
                <Typography variant="h1">
                    Primera vez
                </Typography><br></br>
                <Typography sx={{ mb: 1 }} variant="h3" style={{ fontWeight: 400, color: "#fff" }}>
                    Trámite de licencia de conducir por primera vez.
                </Typography>
            </Grid>

            <Grid item sm={12} sx={{ mb: 0 }}>
                <Typography variant="h3" style={{ color: "#9568C6", fontWeight: 500 }}> <br />
                    El solicitante  deberá presentar los siguientes requisitos en original:
                </Typography>

                <List sx={{ width: '100%' }}>

                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        Llevar confirmación de ésta cita impresa
                                    </Typography>
                                </React.Fragment>}
                        />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        Acta de nacimiento (Original)
                                    </Typography>
                                </React.Fragment>}
                        />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        Posteriormente, al validar su prueba de aptitudes para la conducción de vehículos en el plantel, debe presentarse en ventanilla de cajas de Recaudación de rentas en el edificio de Licencias de Conducir en Blvd. Ortiz Mena 4054 Col. Fovissste, con su credencial que lo acredita como alumno del plantel y realizar el pago de “validación de aptitudes teóricas y prácticas de conducción de vehículos” y presentar el recibo en el departamento de Educación vial, en el mismo edificio.
                                        <br />
                                        - El padre, madre o tutor legal deberá tomar una plática (los datos de la plática se presentan en la confirmación de cita, la cual debe presentarse impresa, junto con la identificación oficial).
                                    </Typography>
                                </React.Fragment>}
                        />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        CURP del solicitante
                                    </Typography>
                                </React.Fragment>}
                        />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        Credencial del COBACH
                                    </Typography>
                                </React.Fragment>}
                        />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        Comprobante de domicilio reciente a nombre de uno de los padres
                                    </Typography>
                                </React.Fragment>}
                            secondary={
                                <React.Fragment>
                                    {<Typography>
                                        
                                    </Typography>}
                                </React.Fragment>
                            }
                        />
                    </ListItem>     
                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ minWidth: 40, marginTop: 0 }}>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h4"
                                        color="text.primary"
                                    >
                                        Padre, madre o tutor legal acudir con identificación oficial y copia de la misma.
                                    </Typography>
                                </React.Fragment>}
                            secondary={
                                <React.Fragment>
                                    {<Typography>
                                        
                                    </Typography>}
                                </React.Fragment>
                            }
                        />
                    </ListItem>                    
                </List>
            </Grid>
            <Grid container rowSpacing={2}>
                <Grid item sm={12}>
                    <Typography sx={{ fontSize: "15px", textAlign: 'center' }}>
                        De acuerdo al artículo 54 de la Ley de Vialidad y Tránsito para el Estado de Chihuahua vigente.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container sx={{ paddingLeft: '27px', paddingRight: '27px' }}>
                <Grid item xs={12} md={12} lg={12} sx={{ mb: 2, mt: 2 }}>
                <Alert severity="info" variant="outlined">
                    <Typography variant="h4" style={{ color: "#9568C6", fontWeight: 500 }}>
                    MENSAJES IMPORTANTES
                    </Typography>
                    <Typography variant="h4" style={{ fontWeight: 500 }}>
                        · El solicitante deberá presentarse al curso de conducción y aprobar las evaluaciones teórico/práctico.
                    </Typography>
                    <Typography variant="h4" style={{ fontWeight: 500 }}>
                        <b style={{ color: '#483260', fontSize: '30px'  }}>· Presentarse 30 minutos antes de su cita.</b>
                    </Typography>
                    <Typography variant="h4" style={{ fontWeight: 500 }}>
                        · Es importante CONOCER tu tipo de sangre.
                    </Typography>
                    <Typography variant="h4" style={{ fontWeight: 500 }}>
                    · Mayores informes 614 42933 00 EXT. 20963 y 20964 
                    </Typography>
                </Alert>
                </Grid>
            </Grid>
        </>
    );
}
export default Block1;