import React, { Fragment, useEffect, useState, forwardRef, ReactElement, Ref } from "react";
import { styled } from '@mui/material/styles';
import { Typography, Box, Divider, Step, StepLabel, Stepper, Stack, Alert, CircularProgress, Container, Link } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Button, { ButtonProps } from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import 'src/assets/css/custom.css';
import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import Final from "./Final";
import PrintIcon from '@mui/icons-material/Print';
import http, { ROUTES } from "src/utils/httpHelper";
import Grid from '@mui/material/Grid'; // Grid version 1
import { useNavigate } from "react-router-dom";
import Block1 from 'src/components/Stepper/Texts/Block1';
import Block2 from 'src/components/Stepper/Texts/Block2';
import Block3 from 'src/components/Stepper/Texts/Block3';
import Block4 from 'src/components/Stepper/Texts/Block4';
import Block5 from 'src/components/Stepper/Texts/Block5';
import Block8 from 'src/components/Stepper/Texts/Block8';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import html2canvas from 'html2canvas';
import ReactGA from 'react-ga';
import { TransitionProps } from '@mui/material/transitions';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, Slide, Avatar, IconButton, Collapse } from "@mui/material";
import { useTranslation } from "react-i18next";

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarUnsuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.main};
      color: ${theme.palette.error.contrastText};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      box-shadow: ${theme.colors.shadows.error};
      top: -${theme.spacing(6)};
      position: absolute;
      left: 50%;
      margin-left: -${theme.spacing(6)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

function getSteps() {
  return ["1", "2", "3"];
}

function getStepContent(control, generalDataController, step) {
  switch (step) {
    case 0:
      ReactGA.event({
        category: 'Appointment',
        action: 'Fecha'
      });
      return <FormOne control={control} generalDataController={generalDataController} />;
    case 1:
      ReactGA.event({
        category: 'Appointment',
        action: 'Informacion_Personal'
      });
      return <FormTwo control={control} generalDataController={generalDataController} />;
    case 2:
      ReactGA.event({
        category: 'Appointment',
        action: 'Contacto'
      });
      return <Final control={control} generalDataController={generalDataController} />;
    default:
      return "Unknown step";
  }
}

export default function Main(props) {
  ReactGA.initialize('G-R3RK5N240R');
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  const [isPrinting, setIsPrinting] = useState(false);
  const [isAppointment, setAppointment] = useState(null);
  const [isTutorAppointment, setTutorAppointment] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState(true);
  const { t } = useTranslation();
  const [moduleText, setModuleText] = useState(null);


  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const next = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  }

  const handleNext = async () => {
    switch (activeStep) {
      case 0:
        let stepOneRequiredPassed = await props.generalDataController.trigger([
          'formality_id',
          'licence_type_id',
          'module_id',
          'appointment_date',
          'appointment_time',
        ]);

        if (!stepOneRequiredPassed) {
          return;
        }

        setIsLoading(true);
        http.post(ROUTES.GENERATE_APPOINTMENT, {
          formality_id: props.generalDataController.getValues('formality_id'),
          licence_type_id: props.generalDataController.getValues('licence_type_id'),
          module_id: props.generalDataController.getValues('module_id'),
          appointment_date: props.generalDataController.getValues('appointment_date'),
          appointment_time: props.generalDataController.getValues('appointment_time')
        })
          .then((response: any) => {
            props.generalDataController.setValue('id', response.data.id);
            props.generalDataController.setShowTimer(true);
            props.generalDataController.countdownComponent.current.start();
            next();
          })
          .catch(error => {
            props.generalDataController.enqueueSnackbar(
              error.message != '' ? error.message :
                'Hubo un error al apartar la cita.\n' + error.exception,
              { variant: 'error' });
          })
          .finally(() => {
            setIsLoading(false);
          })
        break;
      case 1:
        let stepTwoRequiredPassed = await props.generalDataController.trigger([
          'licences_year_id',
          'curp',
          'name',
          'last_name',
          'second_last_name',
          'sex',
          'rfc',
          'birthdate',
          'marital_status',
          'nationality_id',
          'phone',
          'email',
          'occupation',
        ]);

        if(props.generalDataController.nationality_id != 1){
          let fm3 = await props.generalDataController.trigger(['fm3']);
          if (!fm3) {
            return;
          }
        }

        if (!stepTwoRequiredPassed) {
          return;
        }

        if (props.generalDataController.age >= 16) {
          if (props.generalDataController.getValues('licence_type_id') == 6 || props.generalDataController.getValues('licence_type_id') == 7) {
            if (props.generalDataController.age >= 18) {
              props.generalDataController.enqueueSnackbar(
                "Favor de seleccionar la licencia correcta, se seleccionó menor de edad",
                { variant: 'error' });
              return;
            }
          } else {
            if (props.generalDataController.age < 18) {
              props.generalDataController.enqueueSnackbar(
                "Favor de seleccionar la licencia correcta, se seleccionó mayor de edad",
                { variant: 'error' });
              return;
            }
          }
        } else {
          props.generalDataController.enqueueSnackbar(
            "Los menores de 16 años no pueden trámitar licencias",
            { variant: 'error' });
          return;
        }

        next();
        break;
      case 2:
        let finalStepRequiredPassed = await props.generalDataController.trigger();
        if (!finalStepRequiredPassed) {
          return;
        }

        if ((props.generalDataController.getValues('licence_type_id') == 6 || props.generalDataController.getValues('licence_type_id') == 7) && props.generalDataController.getValues('formality_id') == 1) {
          if (props.generalDataController.tutorAge < 18) {
            props.generalDataController.enqueueSnackbar(
              'Los menores de 18 años no pueden ser tutores',
              { variant: 'error' }
            );
            return;
          }
        }

        let formData = props.generalDataController.getValues();
        setIsLoading(true);
        http.post(ROUTES.GENERATE_APPOINTMENT, formData)
          .then((response: any) => {
            handleModule(formData.module_id);
            setAppointment(response.data.appointment);
            if (props.generalDataController.getValues('licence_type_id') == 6 || props.generalDataController.getValues('licence_type_id') == 7 && props.generalDataController.getValues('formality_id') == 1) {
              setTutorAppointment(response.data.tutor_appointment);
            }

            next();
            ReactGA.event({
              category: 'Appointment',
              action: 'Resumen'
            });
            props.generalDataController.countdownComponent.current.stop();
            props.generalDataController.setShowTimer(false);
          })
          .catch(error => {
            if (error.message === "Duplicated appointment") {
              handleOpenDialog();
            } else {
              props.generalDataController.enqueueSnackbar(
                error.message != '' ? error.message :
                  'Hubo un error al generar la cita.\n' + error.exception,
                { variant: 'error' });
            }
          })
          .finally(() => {
            setIsLoading(false);
          })
        break;

      default:
        break;
    }
  };

  const handleBack = () => {
    if (activeStep == 0) {
      navigate("/");
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const print = () => {
    setIsPrinting(true);
  }

  useEffect(() => {
    if (isPrinting) {
      const input = document.getElementById('divToPrint');
      const hiddenImage = document.getElementById('hiddenImage') as HTMLImageElement;
      html2canvas(input, { scale: 1.1 })
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          hiddenImage.src = imgData;
        })
        .then(() => {
          window.print();
        })
        .finally(() => {
          setIsPrinting(false);
        });
    }
  }, [isPrinting])

  const handleModule = (module) => {
    switch (module) {
      case 1:
        if(props.generalDataController.getValues('formality_name') == 'Primera vez'){
          setModuleText('Cd. Chihuahua: 614 429 3300 Ext. 20963 y 20964  <br/>')
        }else{
          setModuleText('Cd. Chihuahua: 614 429 3300 Ext. 20511.  <br/>')
        }
        break;
      case 2:
      case 3:
        if(props.generalDataController.getValues('formality_name') == 'Primera vez'){
          setModuleText('Cd. Juárez: 656 629 3300 Ext. 55254. <br/>')
        }else{
          setModuleText('Cd. Juárez: 656 629 3300 Ext. 55254. <br/>')
        }
        break;
      default:
        setModuleText('Cd. Chihuahua: 614 429 3300 Ext. 20511. <br/> Cd. Juárez: 656 629 3300 Ext. 55254. <br/> Licencia por primera vez: <br/> Cd. Chihuahua: 614 429 3300 Ext. 20742. <br/> Cd. Juárez: 656 629 3300 Ext. 55254. <br/>')
        break;
    }
  }    



  const documentation = () => {
    switch (parseInt(props.generalDataController.getValues('formality_id'))) {
      case 1:
        return <Container><Block1 type={1} /></Container>;
      case 2:
        return <Container><Block2 /></Container>;
      case 3:
        return <Container><Block3 /></Container>;
      case 4:
        return <Container><Block4 /></Container>;
      case 5:
        return <Container><Block5 /></Container>;
      case 6:
        return <Container><Block1 type={6} /></Container>;
      case 7:
        return <Container><Block1 type={7} /></Container>;
      case 8:
        return <Container><Block8 /></Container>;
    }
  }

  return (
    <>
      <style>
        {`
          @media print {
            #hiddenDiv {
              display: block !important;
            }
            #divToHide, #logo-bar {
              display: none;
            }
          }
        `}
      </style>
      <div>
        <div id="hiddenDiv" className=".print-image" style={{ display: 'none', width: '100%' }}>
          <img id="hiddenImage" src="" style={{ width: '100%' }} />
        </div>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div id="divToHide">
          {activeStep === steps.length ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Stack id="divToPrint" style={{ width: "70%", alignItems: "center", display: "flex" }} sx={{ mt: 2 }} spacing={2}>
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Alert iconMapping={{ success: <CheckCircleOutlineIcon sx={{ fontSize: "50px" }} />, }} >
                      <Typography variant="h2" sx={{ lineHeight: "unset" }}>¡Listo! Tu cita ha quedado agendada</Typography>
                    </Alert>
                  </CardContent>
                </Card>

                <Card sx={{ minWidth: 275, maxWidth: '70%' }}>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="overline" display="block" gutterBottom>
                          Nombre del solicitante:
                        </Typography>
                        {
                          props.generalDataController.getValues('name') + ' ' +
                          props.generalDataController.getValues('last_name') + ' ' +
                          props.generalDataController.getValues('second_last_name')
                        }
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="overline" display="block" gutterBottom>
                          Folio:
                        </Typography>
                        {props.generalDataController.getValues('id')}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="overline" display="block" gutterBottom>
                          Cita:
                        </Typography>
                        {isAppointment.date} &nbsp;
                        {props.generalDataController.getValues('appointment_time')} Hrs
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="overline" display="block" gutterBottom>
                          Tipo de trámite:
                        </Typography>
                        {props.generalDataController.getValues('formality_name')}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="overline" display="block" gutterBottom>
                          Módulo:
                        </Typography>
                        {props.generalDataController.selectsData.modules.find(module => module.id == props.generalDataController.getValues('module_id'))?.name}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="overline" display="block" gutterBottom>
                          Tipo de licencia:
                        </Typography>
                        {props.generalDataController.selectsData.licenceTypes.find(licenceType => licenceType.id == props.generalDataController.getValues('licence_type_id'))?.name}
                      </Grid>
                      {/* <Grid item xs={12} md={6}>
                        <Typography variant="overline" display="block" gutterBottom>
                          Costo de la licencia:
                        </Typography>
                        $ {props.generalDataController.amount} MxN
                      </Grid> */}
                      <Grid item xs={12} md={6}>
                        <Typography variant="overline" display="block" gutterBottom>
                          Dirección: 
                        </Typography>
                        {props.generalDataController.getValues('module_id') == 1 ? (
                          <div className="">
                            Blvd. Antonio Ortiz Mena 4054 Col. FOVISSSTE, Chihuahua, Chih.
                          </div>
                        ) : props.generalDataController.getValues('module_id') == 2 ? (
                          <div className="">
                            Palacio de mitla #1151-2 Oriente, Bosques de Salvarcar, CP 32575, Cd. Juárez, Chih.
                          </div>
                        ) : props.generalDataController.getValues('module_id') == 3 ? (
                          <div className="">
                            Av. Abraham Lincoln 1290, CP 32000, Cd. Juárez, Chih.
                          </div>
                        ) : 
                          <div className="">
                            Chihuahua. Blvd. Antonio Ortiz Mena 4054 Col. FOVISSSTE, Chihuahua, Chih. <br/>
                            Juárez "Pueblito Mexicano". Av. Abraham Lincoln 1290, CP 32000, Cd. Juárez, Chih. <br/>
                            Juárez Mitla. Palacio de mitla #1151-2 Oriente, Bosques de Salvarcar, CP 32575, Cd. Juárez, Chih.
                          </div>
                        }
                      </Grid>
                    </Grid>

                    {
                      ((props.generalDataController.getValues('licence_type_id') == 6 || props.generalDataController.getValues('licence_type_id') == 7) && props.generalDataController.getValues('formality_id') == 1) &&
                      <Fragment>
                        <Divider style={{ marginTop: 10, marginBottom: 10 }}></Divider>
                        <Grid container>
                          <Grid item xs={12} md={8}>
                            <Typography variant="overline" display="block" gutterBottom>
                              Nombre del tutor del solicitante:
                            </Typography>
                            {
                              props.generalDataController.getValues('tutor_name') + ' ' +
                              props.generalDataController.getValues('tutor_last_name') + ' ' +
                              props.generalDataController.getValues('tutor_second_last_name')
                            }
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Typography variant="overline" display="block" gutterBottom>
                              Folio:
                            </Typography>
                              { isTutorAppointment.id}
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Typography variant="overline" display="block" gutterBottom>
                              Tipo de trámite:
                            </Typography>
                              Plática para tutores
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Typography variant="overline" display="block" gutterBottom>
                              Cita:
                            </Typography>
                            {isTutorAppointment.date} {props.generalDataController.getValues('module_id') === 1 ? props.generalDataController.getValues('appointment_time') : isTutorAppointment.time} Hrs
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Typography variant="overline" display="block" gutterBottom>
                              Módulo:
                            </Typography>
                            {props.generalDataController.selectsData.modules.find(module => module.id == props.generalDataController.getValues('module_id'))?.name}
                          </Grid>
                        </Grid>
                      </Fragment>
                    }

                    {(!isPrinting) && <Fragment>
                      <Button
                        variant="outlined"
                        startIcon={<PrintIcon />}
                        fullWidth
                        sx={{ mt: 2 }}
                        onClick={print}
                      >
                        Imprimir
                      </Button>

                      <Link href={ROUTES.BASE+`/api/cita-pdf/${props.generalDataController.getValues('id')}`} target="_blank">
                        <Button variant="outlined" startIcon={<PictureAsPdfIcon />} fullWidth sx={{ mt: 2 }}>
                          Exportar a PDF
                        </Button>
                      </Link>

                    </Fragment>}
                  </CardContent>
                </Card>

                {documentation()}
                <Grid container sx={{ paddingLeft: '27px', paddingRight: '27px' }}>
                  <Grid item xs={12} md={12} lg={12} sx={{ mb: 2, mt: 2 }}>
                    <Alert severity="info" variant="outlined">
                      <Typography variant="h4" style={{ fontWeight: 500 }}>
                        MENSAJES IMPORTANTES
                      </Typography>
                      {props.generalDataController.getValues('formality_name') == 'Primera vez' ?
                        <div>
                           <Typography variant="h4" style={{ fontWeight: 500 }}>
                            · <b style={{ color: '#483260', fontSize: '30px' }}> Presentarse 30 minutos antes de su cita, ya que la misma caduca en el momento de cumplirse.</b> <br />
                            · Para recibir atención es indispensable contar con su cita impresa y/o digital (Este documento) y portar una identificación oficial vigente con fotografía y firma. <br></br>
                            · Es importante cumplir con todos los requisitos de acuerdo a lo establecido de su cita. <br></br>
                            · Deberá pagar curso de validación de aptitudes teóricas y prácticas de conducción de vehículos<br></br>
                            · El pago del curso se puede realizar el mismo día de su cita en las cajas de Recaudación de Rentas de las mismas instalaciones.<br></br><br></br>
                           {/*  · El pago del curso se puede realizar en el siguiente link: <a href="https://ipagos.chihuahua.gob.mx/pagos/PagosDiversos/" target="_blank" rel="noopener noreferrer">https://ipagos.chihuahua.gob.mx/pagos/PagosDiversos/</a><br></br>
                            &emsp; &emsp; <b  style={{ color: "#9568C6", fontWeight: "bold" }}>INSTRUCCIONES PARA PAGO EN LÍNEA:</b> <br></br>
                            &emsp; &emsp;· Seleccionar “Vehicular diversos”, Aceptar. <br></br>
                            &emsp; &emsp;· ⁠Contribuyente (escriba y verifique su nombre completo tal como aparece en su identificación oficial). <br></br>
                            &emsp; &emsp;· Referencia Concepto: Validación de aptitudes teóricas y prácticas de conducción de vehículos. <br></br>
                            · Al realizar el pago del curso en línea deberá presentarlo de manera impresa. Este pago se verá reflejado 72 horas después de realizarlo. <br></br> */}
                          </Typography>
                        </div>
                      : <div>
                           <Typography variant="h4" style={{ fontWeight: 500 }}>
                            · Presentarse 15 minutos antes de su cita, ya que la misma caduca en el momento de cumplirse. <br></br>
                            · Para recibir atención es indispensable contar con su cita impresa y/o digital (Este documento) y portar una identificación oficial vigente con fotografía y firma. <br></br>
                            · Es importante cumplir con todos los requisitos de acuerdo a lo establecido de su cita. <br></br>
                          </Typography>
                        </div>
                      }
                     
                    </Alert>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Alert severity="info" variant="outlined">
                      <Typography variant="h4" style={{ fontWeight: 500 }}>
                        IMPORTANTE
                      </Typography>
                      <Typography variant="h4" style={{ fontWeight: 500 }}>
                        {props.generalDataController.getValues('formality_name') == 'Primera vez' ?
                         <div>· En caso de licencia para motociclista presentar (moto y casco) únicamente en Chihuahua capital. <br></br></div> 
                         : null 
                        }
                        · En caso de no requerir la cita agendada, tienes hasta una hora antes de tu cita para cancelarla y evitar que esta cuente como asistencia.<br></br>
                        · Si no asistes a dos citas en un periodo de 15 días, no podrás registrar una nueva cita por sesenta días naturales a partir de la segunda asistencia.<br></br>
                        · Puede cancelar su cita en <a href="https://citassspe.chihuahua.gob.mx/info">https://citassspe.chihuahua.gob.mx/info</a>, seleccionando el trámite "CANCELAR CITA", capturando su folio de cita que se encuentra en su hoja de confirmación que le fue enviada por correo, y después puede programar una nueva cita, o si requiere más información puede comunicarse al teléfono: <br></br>

                         <div dangerouslySetInnerHTML={{ __html: moduleText }}></div>
                      </Typography>
                    </Alert>
                  </Grid>
                </Grid>
                {(!isPrinting) && <Button href="/info">Volver al inicio</Button>}
              </Stack>
            </div>
          ) : (
            <div>
              <Typography>
                {getStepContent(props.control, props.generalDataController, activeStep)}
              </Typography>

              <Divider sx={{ mt: 2, mb: 2 }}></Divider>

              <Box>
                <Button
                  onClick={handleBack}
                  color="secondary"
                  variant="outlined"
                >
                  <ChevronLeftIcon />
                  Regresar
                </Button>

                <Button
                  variant="contained"
                  onClick={handleNext}
                  style={{ float: "right" }}
                  disabled={isLoading}
                  startIcon={
                    isLoading ? <CircularProgress size="1rem" /> : null
                  }
                >
                  {activeStep === steps.length - 1 ? "Terminar" : "Siguiente"}
                  <ChevronRightIcon />
                </Button>
              </Box>
            </div>
          )}
        </div>
      </div>

      <DialogWrapper
        open={openDialog}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
      >
        <Box
          sx={{
            px: 4,
            pb: 4,
            pt: 10
          }}
        >
          <AvatarUnsuccess>
            <ErrorOutlineOutlinedIcon />
          </AvatarUnsuccess>

          <Collapse in={openAlert}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              severity="info"
            >
              <Typography variant="body1" component="div">
                {t('DUPLICATED-APPOINTMENT.INFO')}
                <br />
                <br />
                {t('DUPLICATED-APPOINTMENT.CONTACT_DATA_RENOVATION')}
                <br />
                {t('DUPLICATED-APPOINTMENT.CONTACT_DATA_RENOVATION_CH')}
                <br />
                {t('DUPLICATED-APPOINTMENT.CONTACT_DATA_RENOVATION_JU')}
                <br />
                <br />
                {t('DUPLICATED-APPOINTMENT.CONTACT_DATA_FIRSTIME')}
                <br />
                {t('DUPLICATED-APPOINTMENT.CONTACT_DATA_FIRSTIME_CH')}
                <br />
                {t('DUPLICATED-APPOINTMENT.CONTACT_DATA_FIRSTIME_JU')}
              </Typography>
            </Alert>
          </Collapse>
          <Typography
            align="center"
            sx={{
              py: 4,
              px: 10
            }}
            variant="h3"
          >
            {t('DUPLICATED-APPOINTMENT.TITLE')}
          </Typography>

          <Button
            fullWidth
            size="large"
            variant="contained"
            onClick={handleCloseDialog}
          >
            {t('DUPLICATED-APPOINTMENT.BUTTON')}
          </Button>
        </Box>
      </DialogWrapper>
    </>
  );
}